import { Icon } from "../../../themes/userq/Icon/Icon";
import { Tag } from "../../../themes/userq/Tag/Tag";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Search } from "../../../themes/userq/Search/Search";
import { TextArea } from "../../../themes/userq/TextArea/TextArea";

import "./survey_creator_ai.css";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton";

export default function SurveyCreatorAI({switchToManual}){

    const lengthOptions = [{
            label: "Short - 5/7 questions - just to cover the basics",
            value: "short",
        },
        {
            label: "Medium - 8/12 questions - a need to get some detailed info",
            value: "medium",
        },
        {
            label: "Long - 13/20 questions - I need to deep dive into the topic",
            value: "long",
        }
    ];

    const surveyTypeOptions = [{
            label: "Close ended questions only",
            value: "close ended",
        },
        {
            label: "Open ended questions only (heavier for the user)",
            value: "open ended",
        },
        {
            label: "A good mix of both",
            value: "long",
        }
    ];

    return (
        <>
            <div className="ai-wrap">
                <div className="ai-creator-top d-flex align-items-center justify-content-between">
                    <div className="back-btn none-for-desktop">
                        <a class="back-arrow-btn" href="#">
                            <Icon value={"back-arrow"} />
                        </a>
                    </div>
                    <div className="d-flex align-items-center ai-creator-top-left">
                        <Text
                            type={"subtitle-2"}
                            fontWeight={"medium-font"}
                        >
                            Survey creator
                        </Text>

                        <Tag
                            title={"Powered by AI"}
                            type={"blue-tag"}
                            iconLeft={
                                <Icon
                                    value={"Ai"}
                                    size={"medium"}
                                    colorClass={"gray-900-svg"}
                                />
                            }
                            size={"large-tag"}
                        />
                    </div>

                    <div className="d-flex align-items-center ai-creator-top-right none-for-mobile">
                        <Button
                            type={"secondary"}
                            size={"small"}
                            label={"Back to manual"}
                            onClick={()=>{
                                switchToManual();
                            }}
                        />
                    </div>
                </div>

                

                <div className="add-ai-logic-form-wrap mt-40">
                    <Text
                        type={"body-text-1"}
                        fontWeight={"medium-font"}
                    >
                        What’s your goal?
                    </Text>

                    <Text
                        type={"body-text-3"}
                        cssClasses={"gray-color mt-8"}
                    >
                        Provide a description below of the goal
                        you want to achieve from this survey.
                    </Text>

                    <div className="ar-textarea-wrap mt-20">
                        <TextArea
                            autosize={true}
                            placeholder={
                                "I want to create a survey..."
                            }
                        />
                    </div>
                </div>

                <div className="add-ai-logic-form-wrap mt-40">
                    <Text
                        type={"body-text-1"}
                        fontWeight={"medium-font"}
                    >
                        What’s the length of the survey?
                    </Text>

                    <Text
                        type={"body-text-3"}
                        cssClasses={"gray-color mt-8"}
                    >
                        Lorem
                    </Text>

                    <div className="ar-textarea-wrap mt-20">
                        {lengthOptions.map(function(option){
                            return <RadioButton key={option.label} label={option.label} value={option.value} />
                        })}
                        
                    </div>
                </div>
                <div className="add-ai-logic-form-wrap mt-40">
                    <Text
                        type={"body-text-1"}
                        fontWeight={"medium-font"}
                    >
                        Which kind of questions you want to include?
                    </Text>

                    <Text
                        type={"body-text-3"}
                        cssClasses={"gray-color mt-8"}
                    >
                        Lorem
                    </Text>

                    <div className="ar-textarea-wrap mt-20">
                        {surveyTypeOptions.map(function(option){
                            return <RadioButton key={option.label} label={option.label} value={option.value} />
                        })}
                        
                    </div>
                </div>

                <div className="generate-ai-btn text-center">
                    <Button
                        type={"primary"}
                        size={"large"}
                        label={"Generate"}
                        iconRight={
                            <Icon
                                value={"Ai"}
                                colorClass={"gray-50-svg"}
                            />
                        }
                    />
                </div>

                <div className="back-to-manual-btn">
                    <Button
                        type={"ghost"}
                        size={"large"}
                        label={"Back to manual"}
                        iconLeft={
                            <Icon
                                value={"back-arrow"}
                                colorClass={"gray-900-svg"}
                            />
                        }
                        onClick={()=>{
                            switchToManual();
                        }}
                    />
                </div>
            </div>
        </>
    ); 
};